import { ReactComponent as ChatIcon } from "bootstrap-icons/icons/chat.svg";
import type { FC, HTMLAttributes } from "react";
import { useEffect, useState } from "react";
import { Badge } from "@gs/uiKit/dataDisplay/Badge/Badge";
import { leadApi } from "domain/lead/leadApi";
import { dataBus } from "services/dataBus";
import { useCurrentUser } from "feature/session/hook/useCurrentUser";
import { loadAllSenderProfiles } from "domain/senderProfile/util/loadAllSenderProfiles";
import difference from "lodash/difference";
import sse from "services/serverSideEvents";
import { wait } from "@gs/core/utils/wait";
import { isMessageActivityMap } from "@gs/core/domain/Activity/ActivityType";

export const MessagesMenuItemIcon: FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
  const [isHaveUnreadMessages, setIsHaveUnreadMessages] = useState(false);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const allSenderProfilesPromise = loadAllSenderProfiles();
    const getMetrics = async () => {
      const allSenderProfilesIds = (await allSenderProfilesPromise).map((sp) => sp.uuid);
      const hiddenSenderProfileIds = currentUser?.config.messenger_hidden_sender_profiles?.ids || [];
      const senderProfileIds = difference(allSenderProfilesIds, hiddenSenderProfileIds);

      const response = await leadApi.getLeadMetrics({
        metrics: ["unread_count_linkedin", "unread_count_email"],
        senderProfileIds,
      });
      const { unread_count_linkedin, unread_count_email } = response.data.total;
      if (unread_count_linkedin > 0 || unread_count_email > 0) {
        setIsHaveUnreadMessages(true);
      } else {
        setIsHaveUnreadMessages(false);
      }
    };

    getMetrics();

    const unsubscribers = [
      dataBus.subscribe("messages_read", getMetrics),
      dataBus.subscribe("messages_unread", getMetrics),
      sse.listen(async (events) => {
        const isSomeMessageActivity = events.some((event) => {
          if (event.type !== "activity_event") return false;
          return isMessageActivityMap[event.activity.type];
        });
        if (!isSomeMessageActivity) return;
        await wait(1000);
        getMetrics();
      }),
    ];

    return () => unsubscribers.forEach((cb) => cb());
  }, []);

  return (
    <Badge
      dot={isHaveUnreadMessages}
      color="var(--color-primary)"
      {...props}
    >
      <ChatIcon />
    </Badge>
  );
};
