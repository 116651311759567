import * as consts from "./consts";
import getAxios from "services/axios";
import { createCachedFunction } from "../utils";
import { TaskStatus } from "./model/types";
import { key as tasksPageKey } from "../../../components/routes/Tasks/reducer/reducer";
import { dataBus } from "services/dataBus";


const axios = getAxios("flowV2");

// const getTaskForLead = (id, cancelToken) => (dispatch) => {
//   return axios.get(`/api/tasks/lead/${id}`, {cancelToken})
//     .then(res => {

//       dispatch({
//         type: consts.TASKS_SET,
//         payload: res,
//       })

//       return res;
//     })
// }

// const getTasks = (params, cancelToken) => (dispatch) => {
//   return axios.get(`/api/tasks`, {params, cancelToken})
//     .then(res => {
//       dispatch({
//         type: consts.TASKS_SET,
//         payload: res.data,
//       })

//       return res;
//     })
// }

const getTaskForLead = (params) => (dispatch) => {
  return axios.get("/api/tasks/", params)
    .then((res) => {
      dispatch({
        type: consts.TASKS_SET,
        payload: res,
      });

      return res;
    });
};
const createNewTask = (params) => (dispatch) => {
  return axios.post("/api/tasks", params)
    .then((res) => {
      dispatch({
        type: consts.TASKS_SET_UPDATE,
        payload: res.data,
      });

      return res;
    });
};

const cacher = createCachedFunction((id) => (dispatch) => {
  return axios.get(`/api/tasks/${id}`)
    .then( (res) => {
      dispatch({
        type: consts.TASKS_SET_UPDATE,
        payload: res,
      });

      return res;
    });
}, { recursively: true });

const getTaskById = cacher.cachedFunction;

export const getParentTask = async (id) => {
  const parentTask = await axios.get(`/api/tasks/${id}`);
  return parentTask;
};


// const getTaskById = (id) => (dispatch)=>{
//   return axios.get(`/api/tasks/${id}`)
//     .then(res => {
//       dispatch({
//         type: consts.TASKS_SET,
//         payload: res.data,
//       })

//       return res;
//     })
// }

const getTasks = (params) => (dispatch) => {
  return axios.get("/api/tasks", { params })
    .then((res) => {
      dispatch({
        type: consts.TASKS_SET,
        payload: res.data,
      });

      return res;
    });
};


const cancelTask = (id) => (dispatch) => {
  return axios.put(`/api/tasks/${id}/cancel`)
    .then((res) => {
      dispatch({
        type: consts.TASKS_SET_EXECUTE,
        payload: id,
      });
      dataBus.emit("task:updated", { taskIds: [id] });
      return res;
    });
};

const updateTask = (id, params) => (dispatch) => {
  return axios.put(`/api/tasks/${id}`, params)
    .then((res) => {
      dispatch({
        type: consts.TASKS_SET_UPDATE,
        payload: res,
      });
      dataBus.emit("task:updated", { taskIds: [id] });
      return res;
    });
};

const changeTaskOwner = (filter, ownerId) => (dispatch) => {
  return axios.put("/api/tasks/assign-to", { filter: { uuid: filter.ids }, assign_to: ownerId })
    .then((res) => {
      dispatch({
        type: consts.TASKS_SET_UPDATE,
        payload: res,
      });
      return res;
    });
};

const completeTask = ({ uuid, payload }) => (dispatch) => {
  return axios.put(`/api/tasks/${uuid}/complete`, { payload })
    .then((res) => {
      dispatch({
        type: consts.TASKS_SET_UPDATE,
        payload: res,
      });
      dataBus.emit("task:updated", { taskIds: [uuid] });
      return res;
    });
};

export const massCompleteTask = ({ ids }) => (dispatch, getState) => {
  const tasksState = getState().tasksPage.staticFilters;
  return axios.put(
    "/api/tasks/mass-complete",
    {
      filter: {
        status: [TaskStatus.DELEGATED, TaskStatus.NEWBIE, TaskStatus.IN_PROGRESS],
        automation: "manual",
        assigned_to: tasksState.assigned_to.value,
        schedule_at: tasksState.schedule_at,
        sender_profile_uuid: tasksState.sender_profile_uuid.value,
        type: tasksState.type.value,
        uuid: ids,
      },
    },
  )
    .then((res) => {
      dispatch({
        type: consts.TASKS_SET_UPDATE,
        payload: res,
      });
      return res;
    });
};

export const massCancelTask = ({ ids }) => (dispatch, getState) => {
  const { staticFilters, selection } = getState()[tasksPageKey];
  const isAllSelected = selection.all;
  let params;
  if (isAllSelected) {
    params = {
      filter: {
        status: [TaskStatus.DELEGATED, TaskStatus.NEWBIE, TaskStatus.IN_PROGRESS],
        automation: "manual",
        schedule_at: staticFilters.schedule_at,
      } };
    if (ids.length > 0) {
      params.filter.uuid = { "<>": ids };
    }
    if (staticFilters.type.value.length > 0) {
      params.filter.type = staticFilters.type.value;
    }
    if (staticFilters.sender_profile_uuid.value.length > 0) {
      params.filter.sender_profile_uuid = staticFilters.sender_profile_uuid.value;
    }
    if (staticFilters.assigned_to.value.length > 0) {
      params.filter.assigned_to = staticFilters.assigned_to.value;
    }
  } else {
    params = {
      filter: {
        uuid: ids,
      } };
  };
  return dispatch(massCancelTaskWithParams(params));
};

export const massCancelTaskWithParams = ( params ) => (dispatch) => {
  return axios.put("/api/tasks/mass-cancel", params).then((res) => {
    dispatch({
      type: consts.TASKS_SET_UPDATE,
      payload: res.data,
    });
    return res;
  });
};

const setDelegateStatus = (id) => (dispatch) => {
  return axios.put(`/api/tasks/${id}/delegate`)
    .then((res) => {
      dispatch({
        type: consts.TASKS_SET_EXECUTE,
        payload: id,
      });
      return res;
    });
};

const getNextTaskiInLine = (params) => {
  return axios.post("api/tasks/next-task-in-line", params );
};

const getPreviousTaskInLine = (params) => {
  return axios.post("api/tasks/previous-task-in-line", params );
};

const getNextTask = (params, cancelToken) => () => {
  return axios.get("/api/tasks/next-linkedin-auto-task", { params, cancelToken });
};

const executeTask = (id, branchId) => (dispatch) => {
  return axios.put(`/api/tasks/${id}/complete`, { payload: { branch_id: branchId } })
    .then((res) => {

      if (res.updated) {
        dispatch({
          type: consts.TASKS_SET_EXECUTE,
          payload: id,
        });
      }

      return res;
    });
};

const getInProgressTasksCount = (filter) => {
  return axios.post("/api/tasks/count", filter);
};

export {
  getTaskForLead,
  getTasks,
  updateTask,
  setDelegateStatus,
  cancelTask,
  getNextTask,
  executeTask,
  createNewTask,
  getTaskById,
  changeTaskOwner,
  getNextTaskiInLine,
  getPreviousTaskInLine,
  getInProgressTasksCount,
  completeTask,
};
