import { getBaseAxios } from "services/axios";
import { linkedinBrowserStore } from "domain/linkedinBrowser/linkedinBrowserStore";
import { createLinkedinBrowserApi } from "@gs/core/domain/LinkedinBrowser/createLinkedinBrowserApi";
import type { LinkedinBrowser, LinkedinBrowserId } from "@gs/core/domain/LinkedinBrowser/LinkedinBrowser";

export const linkedinBrowserApi = (() => {
  const flowAxios = getBaseAxios("browsers");

  const coreLinkedinBrowserApi = createLinkedinBrowserApi(flowAxios);

  const getLinkedinBrowser: typeof coreLinkedinBrowserApi.getLinkedinBrowser = async (...args) => {
    const result = await coreLinkedinBrowserApi.getLinkedinBrowser(...args);

    linkedinBrowserStore.setState({
      [result.data.id]: result.data,
    });

    return result;
  };

  const getLinkedinBrowsers: typeof coreLinkedinBrowserApi.getLinkedinBrowsers = async (...args) => {
    const result = await coreLinkedinBrowserApi.getLinkedinBrowsers(...args);

    linkedinBrowserStore.setState(
      result.data.data.reduce<Record<LinkedinBrowserId, LinkedinBrowser>>((acc, linkedinBrowser) => {
        acc[linkedinBrowser.id] = linkedinBrowser;
        return acc;
      }, {}),
    );

    return result;
  };

  const createLinkedinBrowser: typeof coreLinkedinBrowserApi.createLinkedinBrowser = async (...args) => {
    const result = await coreLinkedinBrowserApi.createLinkedinBrowser(...args);

    linkedinBrowserStore.setState({
      [result.data.id]: result.data,
    });

    return result;
  };

  const deleteLinkedinBrowser: typeof coreLinkedinBrowserApi.deleteLinkedinBrowser = async (...args) => {
    const result = await coreLinkedinBrowserApi.deleteLinkedinBrowser(...args);

    return result;
  };

  const updateLinkedinBrowser: typeof coreLinkedinBrowserApi.updateLinkedinBrowser = async (...args) => {
    const result = await coreLinkedinBrowserApi.updateLinkedinBrowser(...args);

    linkedinBrowserStore.setState({
      [result.data.id]: result.data,
    });

    return result;
  };

  const shareLinkedinBrowser: typeof coreLinkedinBrowserApi.shareLinkedinBrowser = async (...args) => {
    const result = await coreLinkedinBrowserApi.shareLinkedinBrowser(...args);

    linkedinBrowserStore.setState({
      [result.data.id]: result.data,
    });

    return result;
  };

  const updateProxy: typeof coreLinkedinBrowserApi.updateProxy = async (...args) => {
    const result = await coreLinkedinBrowserApi.updateProxy(...args);

    linkedinBrowserStore.setState({
      [result.data.id]: result.data,
    });

    return result;
  };

  return {
    getLinkedinBrowser,
    getLinkedinBrowsers,
    createLinkedinBrowser,
    updateLinkedinBrowser,
    deleteLinkedinBrowser,
    shareLinkedinBrowser,
    updateProxy,
    checkProxy: coreLinkedinBrowserApi.checkProxy,
    stopLinkedinBrowser: coreLinkedinBrowserApi.stopLinkedinBrowser,
    runLinkedinBrowser: coreLinkedinBrowserApi.runLinkedinBrowser,
  };
})();
