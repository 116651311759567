import styles from "./UnauthLayout.module.scss";
import Preloader from "components/common/elements/Preloader";
import { FullLogo } from "components/common/Logo/FullLogo";
import { useCurrentUser } from "feature/session/hook/useCurrentUser";
import browserHistory from "services/browserHistory";
import { Flex } from "@gs/uiKit/layout/Flex";
import type { ReactNode } from "react";
import { BgBadges } from "./BgBadges";
import ava1Base64 from "./assets/ava1.png";
import ava2Base64 from "./assets/ava2.png";
import { ReactComponent as AppsumoLogo } from "./appsumo.svg";
import { useRouteMatch } from "react-router-dom";
import { oauthPath } from "components/routes/authGroup/Oauth/path";
import { userConfirmationPath } from "components/routes/authGroup/UserConfirmation/path";
import { useSelector } from "react-redux";
import { getIsWhiteLabel, getWhiteLabelName } from "store/whiteLabel/selectors";
import { AsyncWhiteLabelBg } from "./AsyncWhiteLabelBg";

const UnauthLayout = (props: {children: ReactNode}) => {
  const { currentUser, currentUserStatus } = useCurrentUser();
  const isAuth = currentUserStatus === "authorized";
  const oauthRouteMatch = useRouteMatch<any>(oauthPath);
  const needAppsumoLogo = oauthRouteMatch?.params?.provider === "appsumo";
  const isWhiteLabel = useSelector(getIsWhiteLabel);
  const companyName = useSelector(getWhiteLabelName) || "GetSales";

  if (currentUser?.email_status === "unverified") {
    if (window.location.pathname !== userConfirmationPath) {
      browserHistory.push(userConfirmationPath);
    }
  }

  if (isAuth && currentUser?.email_status === "verified") {
    browserHistory.push("/");
    return <Preloader />;
  }

  return (
    <div className={styles.wrapper}>
      <Flex
        vertical
        gap={40}
        justify="center"
        className={styles.content}
      >
        <Flex
          gap="middle"
          align="flex-end"
        >
          <FullLogo style={{ maxHeight: 52, width: "fit-content" }} />
          {needAppsumoLogo && (
            <>
              <span style={{ position: "relative", bottom: 12 }}>with</span>
              <AppsumoLogo style={{ width: 118, height: "fit-content", alignSelf: "center" }} />
            </>
          )}
        </Flex>
        {props.children}
      </Flex>
      <div className={styles.staticRight}>
        <div style={{ width: 752, margin: "auto" }}>
          <div
            className="gs-fs-28 gs-fw-500"
            style={{ color: "white", marginBottom: 10 }}
          >
            Unified Inbox, Unmatched Security
          </div>
          <div
            className="gs-fs-20 gs-mb-xl"
            style={{ color: "white" }}
          >
            Join Outbound Teams Winning Smart! 🧠 🔥
          </div>

          {isWhiteLabel && (
            <AsyncWhiteLabelBg />
          )}
          {!isWhiteLabel && (
            <>
              <Flex
                gap={19}
                justify="space-between"
                className="gs-mb"
              >
                <div style={{ width: 366, minHeight: 356, background: "white", padding: 20, borderRadius: 20 }}>
                  <div style={{ color: "#1C212D", fontSize: 20, lineHeight: "26px", fontWeight: 600, marginBottom: 8 }}>
                    {`"From Pre-Seed to 30k MRR with ${companyName}"`}
                  </div>
                  <div style={{ color: "#313745", marginBottom: 8, lineHeight: "20px" }}>
                    A year ago, right after closing our pre-seed round, we were eager to dive into outbound
                    and gain our first traction. That’s when Peter
                    and
                    {" "}
                    {companyName}
                    {" "}
                    came in — perfect timing!
                  </div>
                  <div style={{ color: "#313745", marginBottom: 8, lineHeight: "20px" }}>
                    We launched our first campaign, and the results were immediate. Fast forward, and we scaled number of senders to ten and shortly hit 30k MRR, generated entirely through outbound.
                  </div>
                  <div style={{ color: "#313745", marginBottom: 20, lineHeight: "20px" }}>
                    Huge thanks to Peter and the
                    {" "}
                    {companyName}
                    {" "}
                    team ❤️
                  </div>
                  <Flex gap={12}>
                    <img
                      src={ava1Base64}
                      width={40}
                      height={40}
                    />
                    <div>
                      <div style={{ color: "#1C212D", fontWeight: 500, lineHeight: "20px", marginBottom: 4 }}>
                        Natallia Drogolchuk 🇺🇸
                      </div>
                      <div style={{ color: "#4E545F", fontSize: 12, lineHeight: "16px" }}>
                        CEO & Founder Elatra.io - B2B SaaS Startup
                      </div>
                    </div>
                  </Flex>
                </div>


                <div style={{ width: 366, minHeight: 356, background: "white", padding: 20, borderRadius: 20 }}>
                  <div style={{ color: "#1C212D", fontSize: 20, lineHeight: "26px", fontWeight: 600, marginBottom: 8 }}>
                    "Best Market Software – Best Market Price"
                  </div>
                  <div style={{ color: "#313745", marginBottom: 8, lineHeight: "20px" }}>
                    We tested numerous platforms: LGM, Waalaxy, Expandi, Dripify, Lemlist, Helper—you name it,
                    we tried it. Honestly, I thought
                    {" "}
                    {companyName}
                    {" "}
                    would
                    be just another automation tool, but it stood out.
                  </div>
                  <div style={{ color: "#313745", marginBottom: 8, lineHeight: "20px" }}>
                    What impressed us the most was the unified multichannel messenger, allowing to manage
                    all senders and communications in one place.
                    The unbeatable price made it even better.
                  </div>
                  <div style={{ color: "#313745", marginBottom: 20, lineHeight: "20px" }}>
                    {companyName}
                    {" "}
                    is a №1 solution to scale outbound  🏆️
                  </div>
                  <Flex gap={12}>
                    <img
                      src={ava2Base64}
                      width={40}
                      height={40}
                    />
                    <div>
                      <div style={{ color: "#1C212D", fontWeight: 500, lineHeight: "20px", marginBottom: 4 }}>
                        Dmitry Krivosheev 🇱🇻
                      </div>
                      <div style={{ color: "#4E545F", fontSize: 12, lineHeight: "16px" }}>
                        CBDO Kodan Soft IT Service Company
                      </div>
                    </div>
                  </Flex>
                </div>
              </Flex>

              <Flex
                align="center"
                className="gs-mb-xl"
              >
                <Flex
                  gap="small"
                  className="gs-mr"
                >
                  {new Array(5).fill(null).map((_, index) => (
                    <svg
                      key={index}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5.41848 23.1641C4.83882 23.462 4.18247 22.9413 4.29917 22.2761L5.54376 15.1819L0.260264 10.1469C-0.233507 9.67639 0.0220934 8.81605 0.683577 8.72208L8.03129 7.67827L11.3075 1.18846C11.6026 0.603848 12.402 0.603848 12.6972 1.18846L15.9733 7.67827L23.3211 8.72208C23.9825 8.81605 24.2381 9.67639 23.7444 10.1469L18.4609 15.1819L19.7055 22.2761C19.8222 22.9413 19.1658 23.462 18.5862 23.1641L12.0023 19.7802L5.41848 23.1641Z"
                        fill="#FF7A68"
                      />
                    </svg>
                  ))}
                </Flex>
                <div
                  style={{ color: "#EFF2F5", marginRight: 6 }}
                  className="gs-fw-700 gs-fs-20"
                >
                  4.9/5
                </div>
                <div
                  style={{ color: "#EFF2F5" }}
                  className="gs-fs-16"
                >
                  based on 300 reviews
                </div>
              </Flex>
              <BgBadges />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnauthLayout;
