export const manualTasksPath = "/manual-tasks";

export const manualTasksPageQueryParams = {
  PAGE: "page",
  PAGE_SIZE: "pageSize",
  ASSIGNEE: "assignee",
  STATUS: "status",
  TYPE: "type",
  LEAD: "contact",
  SENDER_PROFILE: "sender-profile",
  PERIOD: "period",
} as const;

export default manualTasksPath;
